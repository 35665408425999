import React from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons.ts';
import { useStyles } from '@/hooks/useStyles.ts';
import { PatientListItem } from '@/models/PatientSummaryModel.ts';
import { Tag } from '@/uiKit/atoms/Tag.tsx';

export type NoticeColumnProps = CellContext<PatientListItem, boolean>;

export const NurseNoticeColumn: React.FC<NoticeColumnProps> = ({
  getValue,
}) => {
  const styles = useStyles(makeStyles);
  const hasNotice = getValue();

  if (!hasNotice) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Tag
        color="blue"
        icon={<Icons.forward className={styles.icon} />}
        className={styles.noticeTag}
      />
    </div>
  );
};

export const DoctorNoticeColumn: React.FC<NoticeColumnProps> = ({
  getValue,
}) => {
  const styles = useStyles(makeStyles);
  const hasNotice = getValue();

  if (!hasNotice) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Tag
        color="blue"
        icon={<Icons.stethoscope className={styles.biggerIcon} />}
        className={styles.noticeTag}
      />
    </div>
  );
};

export const NoticeHeader: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeHeaderStyles);
  return (
    <div className={styles.container}>
      <Typography>{t('pages.patients.toConsult')}</Typography>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
  noticeTag: css`
    width: ${theme.spacing(16)};
    height: ${theme.spacing(16)};
  `,
  icon: css`
    width: ${theme.typography.body.fontSize};
    height: ${theme.typography.body.fontSize};
  `,
  biggerIcon: css`
    width: ${theme.typography.subtitleLarge.fontSize};
    height: ${theme.typography.subtitleLarge.fontSize};
  `,
});

const makeHeaderStyles = () => ({
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
});
