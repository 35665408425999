import {
  Alert,
  AlertColor,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button } from '@/uiKit/atoms/Button';

const getIntegrationMessage = (status: string) => {
  switch (status) {
    case 'already_authorized':
      return {
        title: 'pages.addMeasurementFile.dexcom.alreadyIntegrated',
        helpText: 'pages.addMeasurementFile.dexcom.alreadyIntegratedHelpText',
        severity: 'success',
      };
    case 'clarity_not_enabled':
      return {
        title: 'pages.addMeasurementFile.dexcom.clarityNotEnabled',
        helpText: 'pages.addMeasurementFile.dexcom.clarityNotEnabledHelpText',
        severity: 'error',
      };
    case 'access_denied':
      return {
        title: 'pages.addMeasurementFile.dexcom.integrationDenied',
        helpText: 'pages.addMeasurementFile.dexcom.integrationDeniedHelpText',
        severity: 'error',
      };
    case 'success':
      return {
        title: 'pages.addMeasurementFile.dexcom.integrationSuccess',
        helpText: 'pages.addMeasurementFile.dexcom.integrationSuccessHelpText',
        severity: 'success',
      };
    case 'error':
    default:
      return {
        title: 'pages.addMeasurementFile.dexcom.integrationError',
        helpText: 'pages.addMeasurementFile.dexcom.integrationErrorHelpText',
        severity: 'error',
      };
  }
};

type DexcomIntegrationStatusProps = {
  status: string;
};

export const DexcomIntegrationStatus = ({
  status,
}: DexcomIntegrationStatusProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { title, helpText, severity } = getIntegrationMessage(status);
  return (
    <Card>
      <CardHeader title={t(title)} />
      <CardContent>
        <Alert severity={severity as AlertColor}>
          <Typography variant="body" style={{ whiteSpace: 'pre-line' }}>
            {t(helpText)}
          </Typography>
        </Alert>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          onClick={() => navigate('/')}
          color="primary"
        >
          {t('button.back')}
        </Button>
      </CardActions>
    </Card>
  );
};
