import React from 'react';

import { css, cx } from '@emotion/css';
import {
  IconButton,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { AlertCategoriesTranslations } from '@/components/notifications/ActiveNotifications';
import { TextToast } from '@/components/toast/TextToast.tsx';
import { useStyles } from '@/hooks/useStyles';
import { AlertConfig } from '@/models/AlertModel';
import { AlertSettingsConditions } from '@/pages/patient-settings/alert-settings/AlertSettingsConditions.tsx';
import { Queries } from '@/queries/Queries.ts';

export type AlertSettingsRowProps = {
  patientId: string;
  alertConfig: AlertConfig;
  className?: string;
  classes?: {
    typeCol?: string;
    conditionsCol?: string;
    actionCol?: string;
  };
};

export const AlertSettingsRow: React.FC<AlertSettingsRowProps> = ({
  patientId,
  alertConfig,
  className,
  classes,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const deleteAlertConfig = Queries.alerts.useDeleteAlertConfig();

  return (
    <TableRow className={cx(styles.container, className)}>
      <TableCell className={classes?.typeCol}>
        <Typography variant="body">
          {t(AlertCategoriesTranslations[alertConfig.category])}
        </Typography>
      </TableCell>
      <TableCell className={classes?.conditionsCol}>
        <AlertSettingsConditions
          patientId={patientId}
          alertConfig={alertConfig}
        />
      </TableCell>
      <TableCell
        className={cx('ActionCell', classes?.actionCol, styles.actions)}
      >
        {alertConfig.can_delete ? (
          <IconButton
            onClick={() =>
              deleteAlertConfig.mutate(
                {
                  patientId,
                  alertConfigId: alertConfig.id,
                },
                {
                  onSuccess: () => {
                    TextToast.success(
                      t('alertConfig.deleteSuccess', {
                        alert: t(
                          AlertCategoriesTranslations[alertConfig.category],
                        ),
                      }),
                    );
                  },
                  onError: () => {
                    TextToast.error(
                      t('alertConfig.deleteError', {
                        alert: t(
                          AlertCategoriesTranslations[alertConfig.category],
                        ),
                      }),
                    );
                  },
                },
              )
            }
          >
            <Icons.trash />
          </IconButton>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    .ActionCell > * {
      visibility: hidden;
    }
    :hover {
      background-color: ${theme.palette.grey['50']};

      .ActionCell > * {
        visibility: visible;
      }
    }
  `,
  typeColumn: css`
    display: flex;
    justify-content: stretch;
    align-items: center;
  `,
  actions: css`
    padding: 0;
  `,
});
