import { useEffect } from 'react';

import { Loader } from '@/components/loading/Loader';
import { Queries } from '@/queries/Queries';
import { DexcomIntegrationStatus } from '@/uiKit/molecules/DexcomIntegrationStatus';

export const Dexcom = () => {
  const dexcomUrlPayload = Queries.diabetes.useGetDexcomAuthUrl({});

  useEffect(() => {
    if (dexcomUrlPayload.data?.url) {
      window.location.href = dexcomUrlPayload.data.url;
    }
  }, [dexcomUrlPayload.data]);

  if (
    dexcomUrlPayload.status == 'pending' ||
    !dexcomUrlPayload.data ||
    dexcomUrlPayload.data.status === 'ready_to_authorize'
  ) {
    return <Loader size="L" />;
  }

  return <DexcomIntegrationStatus status={dexcomUrlPayload.data.status} />;
};
