import { Loader } from '@/components/loading/Loader';
import { DexcomIntegrationStatus } from '@/uiKit/molecules/DexcomIntegrationStatus';

export const DexcomCallback = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const status = searchParams.get('status') ?? '';

  if (status === null || status === '') {
    return <Loader size="L" />;
  }

  return <DexcomIntegrationStatus status={status} />;
};
