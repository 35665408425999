import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { PatientAccountPage } from '@/pages/account/PatientAccountPage';
import { DexcomCallback } from '@/pages/add-measurement/DexcomCallback';
import { MeasurementImportPage } from '@/pages/add-measurement/MeasurementImportPage';
import { PatientLayout } from '@/pages/layout/PatientLayout';

export const PatientRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<PatientLayout outlet />}>
        <Route index element={<MeasurementImportPage />} />
        <Route path="/account" element={<PatientAccountPage />} />
        <Route path="/dexcom/callback" element={<DexcomCallback />} />
      </Route>
    </Routes>
  );
};
