import React, { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { Paper, Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

type InformationPaperProps = PropsWithChildren<{
  variant?: 'elevation' | 'outlined';
  color?: 'primary' | 'secondary';
  isSquare?: boolean;
  padding?: number;
  className?: string;
}>;

export const InformationPaper: React.FC<InformationPaperProps> = ({
  variant = undefined,
  color = undefined,
  children,
  isSquare = false,
  padding = 8,
  className,
}) => {
  const styles = useStyles(makeStyles, isSquare, padding);
  return (
    <Paper
      variant={variant}
      color={color}
      className={cx(styles.paper, className)}
    >
      {children}
    </Paper>
  );
};

const makeStyles = (theme: Theme, isSquare: boolean, padding: number) => ({
  paper: css`
    padding: ${theme.spacing(padding)};
    background-color: ${theme.palette.primary.background};
    border-radius: ${isSquare ? theme.spacing(2) : theme.spacing(8)};
  `,
});
