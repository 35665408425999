import React from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  AlertConfig,
  HyperglycemiaAlertParametersBGM,
  HyperglycemiaAlertParametersCGM,
  HypoglycemiaAlertParametersBGM,
  HypoglycemiaAlertParametersCGM,
  MeasurementAlertParameters,
  TimeAboveRangeCGM,
  TimeBelowRangeCGM,
  TransmissionAlertParameters,
} from '@/models/AlertModel.ts';
import { AlertMeasurementSettings } from '@/pages/patient-settings/alert-settings/conditions/AlertMeasurementSettings.tsx';
import { AlertTransmissionSettings } from '@/pages/patient-settings/alert-settings/conditions/AlertTransmissionSettings.tsx';

import { AlertHyperglycemiaSettings } from './conditions/AlertHyperglycemiaSettings';
import { AlertHyperglycemiaTarSettings } from './conditions/AlertHyperglycemiaTarSettings';
import { AlertHypoglycemiaSettings } from './conditions/AlertHypoglycemiaSettings';
import { AlertHypoglycemiaTbrSettings } from './conditions/AlertHypoglycemiaTbrSettings';

export type AlertSettingsConditionsProps = {
  patientId: string;
  alertConfig: AlertConfig;
};

export const AlertSettingsConditions: React.FC<
  AlertSettingsConditionsProps
> = ({ alertConfig, patientId }) => {
  const { t } = useTranslation();

  if (alertConfig.type == 'bgm') {
    switch (alertConfig.category) {
      case 'hypoglycemia':
        return (
          <AlertHypoglycemiaSettings
            type="bgm"
            patientId={patientId}
            alertConfig={alertConfig}
            parameters={
              alertConfig.parameters as HypoglycemiaAlertParametersBGM
            }
          />
        );
      case 'hyperglycemia':
        return (
          <AlertHyperglycemiaSettings
            type="bgm"
            patientId={patientId}
            alertConfig={alertConfig}
            parameters={
              alertConfig.parameters as HyperglycemiaAlertParametersBGM
            }
          />
        );
    }
  } else if (alertConfig.type == 'cgm') {
    switch (alertConfig.category) {
      case 'time_above_range':
        return (
          <AlertHyperglycemiaTarSettings
            patientId={patientId}
            alertConfig={alertConfig}
            parameters={alertConfig.parameters as TimeAboveRangeCGM}
          />
        );
      case 'time_below_range':
        return (
          <AlertHypoglycemiaTbrSettings
            patientId={patientId}
            alertConfig={alertConfig}
            parameters={alertConfig.parameters as TimeBelowRangeCGM}
          />
        );
      case 'hypoglycemia':
        return (
          <AlertHypoglycemiaSettings
            type="bgm"
            patientId={patientId}
            alertConfig={alertConfig}
            parameters={
              alertConfig.parameters as HypoglycemiaAlertParametersCGM
            }
          />
        );
      case 'hyperglycemia':
        return (
          <AlertHyperglycemiaSettings
            type="cgm"
            patientId={patientId}
            alertConfig={alertConfig}
            parameters={
              alertConfig.parameters as HyperglycemiaAlertParametersCGM
            }
          />
        );
    }
  } else if (alertConfig.type == 'other') {
    switch (alertConfig.category) {
      case 'observance_measures':
        return (
          <AlertMeasurementSettings
            patientId={patientId}
            alertConfig={alertConfig}
            parameters={alertConfig.parameters as MeasurementAlertParameters}
          />
        );
      case 'observance_transmission':
        return (
          <AlertTransmissionSettings
            patientId={patientId}
            alertConfig={alertConfig}
            parameters={alertConfig.parameters as TransmissionAlertParameters}
          />
        );
      default:
        return (
          <Typography variant="body">
            {t('alertConfig.notificationTriggeredAsSoonAsEventIsNoted')}
          </Typography>
        );
    }
  }

  return null;
};
