import React, { MouseEventHandler } from 'react';

import { css, cx } from '@emotion/css';
import { ButtonOwnProps, Button as MuiButton, Typography } from '@mui/material';

import { Loader, LoaderProps } from '@/components/loading/Loader.tsx';
import { useStyles } from '@/hooks/useStyles.ts';
import { AtomProps } from '@/uiKit/atoms/AtomTypes.ts';

type BaseButtonProps = {
  /**
   * The variant to use.
   */
  variant: 'contained' | 'outlined' | 'text';
  /**
   * On click event handler.
   */
  onClick: MouseEventHandler<HTMLButtonElement>;
  /**
   * If `true`, the button will show a loading spinner.
   */
  pending?: boolean;
  /**
   * Props applied to the `Loader` element, when `pending={true}`.
   */
  pendingProps?: LoaderProps;
} & Pick<
  ButtonOwnProps,
  | 'children'
  | 'startIcon'
  | 'endIcon'
  | 'disabled'
  | 'size'
  | 'color'
  | 'fullWidth'
>;

type ButtonStyleClasses = {
  loader?: string;
} & ButtonOwnProps['classes'];

export type ButtonProps = AtomProps<BaseButtonProps, ButtonStyleClasses>;

export const Button: React.FC<ButtonProps> = ({
  pending,
  pendingProps: { className: pendingClassName, ...loaderProps } = {},
  children,
  onClick,
  className,
  ...props
}) => {
  const styles = useStyles(makeStyles);

  return (
    <MuiButton
      {...props}
      className={cx(
        {
          [styles.buttonLoading]: !!pending,
          [styles.iconOnly]:
            !children && (!!props.startIcon || !!props.endIcon),
        },
        className,
      )}
      onClick={pending ? () => {} : onClick}
    >
      {typeof children === 'string' ? (
        <Typography className={styles.text} variant="body">
          {children}
        </Typography>
      ) : (
        children
      )}
      {pending ? (
        <Loader
          size="S"
          className={cx(
            'loader',
            styles.loader,
            props.classes?.loader,
            pendingClassName,
          )}
          {...loaderProps}
        />
      ) : null}
    </MuiButton>
  );
};

const makeStyles = () => ({
  text: css`
    color: inherit;
  `,
  loader: css`
    position: absolute;
  `,
  buttonLoading: css`
    & > *:not(.loader) {
      visibility: hidden;
    }
  `,
  iconOnly: css`
    min-width: 0;
    aspect-ratio: 1;
    padding: 0.5rem;
    & .MuiButton-icon {
      margin: 0;
    }
  `,
});
