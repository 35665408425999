import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AlertCategoriesTranslations } from '@/components/notifications/ActiveNotifications.tsx';
import { TextToast } from '@/components/toast/TextToast.tsx';
import { useStyles } from '@/hooks/useStyles';
import {
  AlertConfig,
  MeasurementAlertParameters,
} from '@/models/AlertModel.ts';
import { HappenedInTheLastXDays } from '@/pages/patient-settings/alert-settings/inputs/HappenedInTheLastXDays.tsx';
import { Queries } from '@/queries/Queries.ts';

import { NumberOfOccurrences } from '../inputs/NumberOfOccurrences';

export type AlertMeasurementSettingsProps = {
  patientId: string;
  alertConfig: AlertConfig;
  parameters: MeasurementAlertParameters;
};

export const AlertMeasurementSettings: React.FC<
  AlertMeasurementSettingsProps
> = ({ patientId, alertConfig, parameters }) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const editAlertConfig = Queries.alerts.useEditAlertConfig();
  const updateParameters = (
    value: unknown,
    key: keyof MeasurementAlertParameters,
  ) => {
    editAlertConfig.mutate(
      {
        patientId,
        alertConfig: {
          id: alertConfig.id,
          parameters: { ...parameters, [key as string]: value },
        },
      },
      {
        onSuccess: () =>
          TextToast.success(
            t('alertConfig.conditionsSuccess', {
              alert: t(AlertCategoriesTranslations.observance_measures),
            }),
          ),
        onError: () =>
          TextToast.error(
            t('alertConfig.conditionsError', {
              alert: t(AlertCategoriesTranslations.observance_measures),
            }),
          ),
      },
    );
  };

  return (
    <div className={styles.container}>
      <NumberOfOccurrences
        textBefore={t('alertConfig.atLeast')}
        textAfter={t('alertConfig.measuresPerDayNoMinumum')}
        onCountChange={count =>
          updateParameters(count, 'min_number_of_measures_per_day')
        }
        minCount={1}
        maxCount={999}
        count={parameters.min_number_of_measures_per_day}
      />
      <HappenedInTheLastXDays
        onDaysChange={days => updateParameters(days, 'number_of_days')}
        minDays={1}
        maxDays={7}
        days={parameters.number_of_days}
      />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
  row: css`
    display: flex;
    flex-direction: row;
  `,
});
