import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';
import { Group } from '@visx/group';
import { Bar, Line } from '@visx/shape';

import { useStyles } from '@/hooks/useStyles';
import { BolusInsulin } from '@/models/diabetes/InsulinModel.ts';
import { SvgDot } from '@/uiKit/atoms/svg/SvgDot.tsx';
import { SvgText } from '@/uiKit/atoms/svg/SvgText.tsx';
import { InsulinPointTooltip } from '@/uiKit/molecules/graphs/InsulinGraph/InsulinTooltips.tsx';
import { toDateTime } from '@/utils/dateUtils.ts';
import {
  SvgLayout,
  boundedDateTimeToSecond,
  dayScale,
  mergeOverlappingData,
  zeroScale,
} from '@/utils/graphUtils.ts';
import { formatNumber } from '@/utils/mathUtils.ts';

const CARBS_SIZE = 28;
const TIME_INCREMENT = 60 * 60; // 60 minutes
const MAX_BOLUS_UNITS = 10;
const BAR_WIDTH = 10;

export type BolusInsulinGraphProps = {
  date: string;
  bolus: BolusInsulin[];
  className?: string;
  paddingTop?: number;
} & SvgLayout;

const BolusInsulinGraph_: React.FC<BolusInsulinGraphProps> = ({
  date,
  bolus,
  className,
  paddingTop = 0,
  ...dim
}) => {
  const styles = useStyles(makeStyles);
  const theme = useTheme();
  const graphArea = {
    top: dim.top + paddingTop,
    left: dim.left,
    width: dim.width,
    height: dim.height - paddingTop,
  };
  const xScale = dayScale(graphArea.width);
  const day = toDateTime(date);

  const mergedData = mergeOverlappingData(
    bolus,
    ins =>
      xScale(
        boundedDateTimeToSecond(day, ins.date, {
          precision: TIME_INCREMENT,
        }),
      ),
    CARBS_SIZE - 4,
  );

  // Convert data to x, y coordinates
  const coordData = mergedData.map(merged => ({
    data: merged.data,
    x: merged.x,
    y: merged.data.reduce((acc, ins) => acc + ins.quantity, 0),
    carbs: merged.data.reduce((acc, ins) => acc + (ins.carbs ?? 0), 0),
    count: merged.data.length,
  }));

  const maxBolus = Math.max(...coordData.map(d => d.y), MAX_BOLUS_UNITS);
  const barGraphHeight = graphArea.height - CARBS_SIZE;
  const yScale = zeroScale(barGraphHeight, maxBolus);
  const scaledMaxBolus = yScale(maxBolus) ?? 0;

  return (
    <Group className={cx(styles.container, className)} {...graphArea}>
      <SvgText
        variant="captionSmall"
        left={-6}
        top={scaledMaxBolus + CARBS_SIZE - 4}
        textAnchor={'end'}
        className={styles.maxBolus}
      >
        {formatNumber(maxBolus, 1)}
      </SvgText>
      <Line
        x1={0}
        x2={-4}
        y1={scaledMaxBolus + CARBS_SIZE}
        y2={scaledMaxBolus + CARBS_SIZE}
        className={styles.maxBolusLine}
      />
      {coordData.map(item => (
        <InsulinPointTooltip insulins={item.data} key={item.data[0].id} x={10}>
          <Bar
            className={styles.bars}
            x={item.x - BAR_WIDTH / 2}
            y={yScale(item.y) + CARBS_SIZE}
            height={graphArea.height - CARBS_SIZE - yScale(item.y)}
            width={BAR_WIDTH}
          />
          {cumulatedInsulin(item.data).map(ins => (
            <Line
              key={ins.id}
              x1={item.x - BAR_WIDTH / 2}
              x2={item.x + BAR_WIDTH / 2}
              y1={yScale(ins.value) + CARBS_SIZE}
              y2={yScale(ins.value) + CARBS_SIZE}
              className={styles.barValue}
            />
          ))}
          <Line
            x1={item.x - BAR_WIDTH / 2}
            x2={item.x - BAR_WIDTH / 2}
            y1={yScale(item.y) + CARBS_SIZE}
            y2={graphArea.height}
            className={styles.barValue}
          />
          <Line
            x1={item.x + BAR_WIDTH / 2}
            x2={item.x + BAR_WIDTH / 2}
            y1={yScale(item.y) + CARBS_SIZE}
            y2={graphArea.height}
            className={styles.barValue}
          />
          {item.carbs && (
            <SvgDot
              color={theme.palette.primary}
              number={item.carbs}
              radius={CARBS_SIZE / 2}
              classes={{
                text: css`
                  font-weight: 100;
                `,
              }}
              left={item.x}
              top={yScale(item.y) + CARBS_SIZE / 2 - 4}
            />
          )}
        </InsulinPointTooltip>
      ))}
    </Group>
  );
};

export const BolusInsulinGraph = React.memo(BolusInsulinGraph_);

type CumulatedInsulin = { id: number; value: number }[];

const cumulatedInsulin = (insulin: BolusInsulin[]) => {
  const result = insulin.reduce(
    (acc, ins) => {
      const sum = acc.sum + ins.quantity;
      const result = [...acc.insulin, { id: ins.id, value: sum }];
      return { sum, insulin: result };
    },
    {
      sum: 0,
      insulin: [],
    } as { sum: number; insulin: CumulatedInsulin },
  );
  return result.insulin;
};

const makeStyles = (theme: Theme) => ({
  container: css`
    stroke: currentColor;
  `,
  bars: css`
    fill: ${theme.palette.insulin.bolus.contrastText};
    stroke-width: 0;
  `,
  barValue: css`
    stroke: ${theme.palette.insulin.bolus.light};
    stroke-width: 1;
  `,
  maxBolus: css`
    fill: ${theme.palette.insulin.bolus.main};
    stroke: transparent;
  `,
  maxBolusLine: css`
    stroke: ${theme.palette.insulin.bolus.main};
    stroke-width: 0.5;
  `,
});
