import React from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

import DEXCOM from '@/assets/icons/dexcom.png';
import FSL_SMARTPHONE_ABBOTT from '@/assets/icons/fsl_smartphone_abbott.jpeg';
import { useQueryString } from '@/hooks/query/useQueryString';
import { useStyles } from '@/hooks/useStyles';
import { SelfPatient } from '@/models/SelfPatientModel';
import { PageContainer } from '@/pages/PageContainer';
import { FileUpload } from '@/pages/add-measurement/FileUpload.tsx';
import { Queries } from '@/queries/Queries';

import { Dexcom } from './Dexcom';

type DeviceType = 'FSL_WITH_ABBOTT_SMARTPHONE' | 'DEXCOM';

const isDeviceType = (device: string): device is DeviceType =>
  device === 'FSL_WITH_ABBOTT_SMARTPHONE' || device === 'DEXCOM';

const getTitlePage = (device: DeviceType | string) => {
  switch (device) {
    case 'FSL_WITH_ABBOTT_SMARTPHONE':
      return 'pages.addMeasurementFile.fsl.addData';
    case 'dexcom':
      return 'pages.addMeasurementFile.dexcom.title';
    default:
      return 'pages.addMeasurementFile.chooseMedicalDeviceToLoad';
  }
};

export const MeasurementImportPage: React.FC = () => {
  const { t } = useTranslation();
  const { device } = useQueryString();
  const hasSelectedADevice = device !== undefined;
  const patient = Queries.patient.usePatient({});
  const styles = useStyles(makeStyles);

  return (
    <PageContainer
      id="measurement-import-page"
      title={t(getTitlePage(device))}
      back={hasSelectedADevice}
      className={styles.container}
    >
      {isDeviceType(device) && patient.status === 'success' ? (
        device == 'DEXCOM' ? (
          <Dexcom />
        ) : (
          <FreeStyleLibre patient={patient.data} device={device} />
        )
      ) : (
        <DeviceCardList
          disabled={
            patient.status !== 'success' ||
            patient.data.user.userType !== 'patient'
          }
        />
      )}
    </PageContainer>
  );
};

const DeviceCardList = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeDeviceCardStyles, disabled);
  const { data: featureFlags } = Queries.user.useFeatureFlags({});

  let deviceList: DeviceCardProps[] = [];
  if (!featureFlags?.['dexcom-inclusion']) {
    deviceList = [
      {
        title: 'pages.addMeasurementFile.fslWithAbbottSmartphone',
        imgSrc: FSL_SMARTPHONE_ABBOTT,
        type: 'FSL_WITH_ABBOTT_SMARTPHONE',
        disabled,
      },
    ];
  } else {
    deviceList = [
      {
        title: 'pages.addMeasurementFile.dexcom.title',
        imgSrc: DEXCOM,
        type: 'DEXCOM',
        disabled,
      },
      {
        title: 'pages.addMeasurementFile.fslWithAbbottSmartphone',
        imgSrc: FSL_SMARTPHONE_ABBOTT,
        type: 'FSL_WITH_ABBOTT_SMARTPHONE',
        disabled,
      },
    ];
  }

  return (
    <div className={styles.deviceCardList}>
      {deviceList.map((device, idx) => (
        <DeviceCard
          disabled={device.disabled}
          key={`${device.type}-${idx}`}
          title={t(device.title)}
          imgSrc={device.imgSrc}
          type={device.type}
        />
      ))}
    </div>
  );
};

type DeviceCardProps = {
  title: string;
  imgSrc: string;
  type: DeviceType;
  disabled: boolean;
};

const DeviceCard = ({ title, imgSrc, type, disabled }: DeviceCardProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const styles = useStyles(makeDeviceCardStyles, disabled);
  const navigate = useNavigate();
  return (
    <div
      className={styles.deviceCard}
      onClick={() => !disabled && navigate(`${pathname}?device=${type}`)}
    >
      <h4>{title}</h4>
      <img src={imgSrc} alt="device" className={styles.deviceImg} />
      <Link to={`${pathname}?device=${type}`}>
        {t('pages.addMeasurement.choose')}
      </Link>
    </div>
  );
};

type DeviceUploadProps = {
  patient: SelfPatient;
  device: DeviceType;
};

const FreeStyleLibre = ({ patient }: DeviceUploadProps) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  return (
    <div className={styles.deviceUpload}>
      <Typography>{t('pages.addMeasurementFile.fsl.instructions')}</Typography>
      <FileUpload patient={patient} />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(12)};
  `,

  deviceUpload: css`
    display: flex;
    flex-direction: column;

    gap: ${theme.spacing(4)};
    box-shadow: ${theme.shadows[4]};
    background-color: ${theme.palette.background.paper};
    flex-grow: 1;
    padding: ${theme.spacing(8)};
  `,
});

const makeDeviceCardStyles = (theme: Theme, disabled: boolean) => ({
  deviceCardList: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.spacing(8)};
  `,
  deviceCard: css`
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(8)};
    align-items: center;
    justify-content: space-between;
    box-shadow: ${theme.shadows[4]};
    background-color: ${disabled
      ? theme.palette.background.default
      : theme.palette.background.paper};
    gap: ${theme.spacing(4)};
    cursor: pointer;
    :hover {
      box-shadow: ${theme.shadows[8]};
    }
  `,
  deviceImg: css`
    max-width: 12rem;
    max-height: 12rem;
  `,
});
