import React, { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import {
  Card,
  CardContent,
  CardHeader,
  Theme,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { FontSizes } from '@/assets/fonts.ts';
import { Icons } from '@/assets/icons.ts';
import { IconButton } from '@/components/button/IconButton.tsx';
import { Loader } from '@/components/loading/Loader.tsx';
import { isScrollNearBottom, useScroll } from '@/hooks/useScroll.ts';
import { useStyles } from '@/hooks/useStyles.ts';
import { InsulinDataDisplayType } from '@/models/DiabetesDisplayModeModel.ts';
import { Patient } from '@/models/PatientModel.ts';
import { Queries } from '@/queries/Queries.ts';
import { DailyGraphContent } from '@/uiKit/organisms/DailyGraphList/DailyGraphContent.tsx';
import { DateUtils } from '@/utils/dateUtils.ts';

export type DailyGraphListProps = {
  patient: Patient;
  to: DateTime;
  insulinDisplayMode: InsulinDataDisplayType;
  className?: string;
  isDexcom: boolean;
};

/**
 * The list of daily graphs for a patient.
 * Infinitely scrollable.
 * The list is sorted by date, from the most recent to the oldest.
 *
 * @param patient
 * @param propsTo
 * @param insulinDisplayMode
 * @param className
 * @constructor
 */
export const DailyGraphList: React.FC<DailyGraphListProps> = ({
  patient,
  to: propsTo,
  insulinDisplayMode,
  className,
  isDexcom,
}) => {
  // Check if the end date (to) is yesterday
  const addTodayData = DateUtils.compareDates(propsTo, DateTime.now()) === -1;
  const from = propsTo.minus({ days: 30 });
  const to = addTodayData ? propsTo.plus({ day: 1 }) : propsTo;

  const scroll = useScroll();
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const dataviz = Queries.diabetes.useInfiniteCGMDailyGraph({
    patientId: patient.id,
    from: from,
    to: to,
  });

  useEffect(() => {
    if (isScrollNearBottom(scroll)) {
      dataviz.fetchNextPage({ cancelRefetch: false });
    }
  });

  return (
    <div className={cx(styles.container, className)}>
      {scroll.current > 1000 ? (
        <div className={styles.backToTop}>
          <IconButton
            buttonType="primary"
            icon={Icons.chevronUp}
            placement={'left'}
            className={styles.moreContentButton}
            iconClassname={styles.moreContentIcon}
            onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
          />
        </div>
      ) : null}
      <Card>
        <CardHeader
          title={
            <>
              <Typography variant="h5">
                {t('pages.patientMonitoring.glycemia.title')}
              </Typography>
              {isDexcom && (
                <Typography variant="body">
                  {t('pages.addMeasurementFile.dexcom.dexcomData')}
                </Typography>
              )}
            </>
          }
        />
        <CardContent>
          <div className={styles.list}>
            {dataviz.hasPreviousPage ? (
              <IconButton
                buttonType="secondary"
                icon={Icons.chevronUp}
                placement="left"
                className={styles.newerDataButton}
                iconClassname={styles.moreContentIcon}
                onClick={() => dataviz.fetchPreviousPage()}
              >
                {t('pages.patientMonitoring.loadMoreRecent')}
              </IconButton>
            ) : null}
            {dataviz.data?.pages
              .flatMap(a => a)
              .map(data => (
                <DailyGraphContent
                  key={data.date}
                  data={data}
                  insulinDisplayMode={insulinDisplayMode}
                  isDexcom={isDexcom}
                />
              ))}
            {dataviz.hasNextPage ? (
              <div className={styles.loader}>
                <Loader size="M" />
              </div>
            ) : null}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
  `,
  backToTop: css`
    position: fixed;
    bottom: ${theme.spacing(12)};
    align-self: flex-end;
    z-index: ${theme.zIndex.fab};
    stroke: ${theme.palette.grey[900]};
  `,
  list: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(4)};
  `,
  newerDataButton: css`
    width: fit-content;
    color: ${theme.palette.text.primary};
  `,
  moreContentButton: css`
    width: fit-content;
    color: ${theme.palette.common.white};
  `,
  moreContentIcon: css`
    width: ${FontSizes.bodyLarge};
    height: ${FontSizes.bodyLarge};
  `,
  loader: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
});
