import React from 'react';

import { css } from '@emotion/css';
import {
  Button,
  CardActions,
  CardContent,
  MenuItem,
  Select,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Loader } from '@/components/loading/Loader.tsx';
import { AlertCategoriesTranslations } from '@/components/notifications/ActiveNotifications.tsx';
import { useStyles } from '@/hooks/useStyles';
import { AlertCategory, AlertType } from '@/models/AlertModel.ts';
import { Queries } from '@/queries/Queries.ts';

export type AlertCreationDialogProps = {
  patientId: string;
  onClose: () => void;
  alertType: AlertType;
};

export const AlertCreationDialog: React.FC<AlertCreationDialogProps> = ({
  patientId,
  alertType,
  onClose,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const createAlertConfig = Queries.alerts.useCreateAlertConfig();
  const [selectedCategory, setSelectedCategory] = React.useState<AlertCategory>(
    alertType == 'other' ? 'severe_hypoglycemia_declared' : 'hypoglycemia',
  );

  let Items = null;
  if (alertType === 'bgm') {
    Items = [
      <MenuItem key="bgm-hypoglycemia" value="hypoglycemia">
        {t(AlertCategoriesTranslations['hypoglycemia'])}
      </MenuItem>,
      <MenuItem key="bgm-hyperglycenia" value="hyperglycemia">
        {t(AlertCategoriesTranslations['hyperglycemia'])}
      </MenuItem>,
    ];
  } else if (alertType === 'cgm') {
    Items = [
      <MenuItem key="cgm-hypoglycemia" value="hypoglycemia">
        {t(AlertCategoriesTranslations['hypoglycemia'])}
      </MenuItem>,
      <MenuItem key="cgm-hyperglycemia" value="hyperglycemia">
        {t(AlertCategoriesTranslations['hyperglycemia'])}
      </MenuItem>,
      <MenuItem key="cgm-tir" value="time_above_range">
        {t(AlertCategoriesTranslations['time_above_range'])}
      </MenuItem>,
      <MenuItem key="cgm=tbr" value="time_below_range">
        {t(AlertCategoriesTranslations['time_below_range'])}
      </MenuItem>,
    ];
  } else if (alertType === 'other') {
    Items = [
      <MenuItem key="observance-mesure" value="observance_measures">
        {t(AlertCategoriesTranslations['observance_measures'])}
      </MenuItem>,
      <MenuItem key="obsercance-transmission" value="observance_transmission">
        {t(AlertCategoriesTranslations['observance_transmission'])}
      </MenuItem>,
      <MenuItem key="severe-hypo-declared" value="severe_hypoglycemia_declared">
        {t(AlertCategoriesTranslations['severe_hypoglycemia_declared'])}
      </MenuItem>,
    ];
  }

  return (
    <>
      <CardContent className={styles.container}>
        <Select
          value={selectedCategory}
          variant="standard"
          onChange={e => setSelectedCategory(e.target.value as AlertCategory)}
        >
          {Items}
        </Select>
      </CardContent>
      <CardActions>
        <Button
          id="create-alert-config"
          variant="contained"
          startIcon={<Icons.plus />}
          onClick={() =>
            createAlertConfig.mutate(
              {
                patientId,
                alertConfig: {
                  category: selectedCategory,
                  type: alertType,
                },
              },
              {
                onSuccess: onClose,
              },
            )
          }
        >
          {createAlertConfig.isPending ? (
            <Loader size="S" />
          ) : (
            t('alertConfig.create')
          )}
        </Button>
        <Button id="cancel-alert-config" variant="outlined" onClick={onClose}>
          {t('button.cancel')}
        </Button>
      </CardActions>
    </>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    margin: ${theme.spacing(16)};
  `,
});
