import React from 'react';

import { css } from '@emotion/css';
import { MenuItem, Select, Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

export type SelectTypeProps<T extends number | string | boolean> = {
  onSelectChange: (value: T) => void;
  options: {
    value: T;
    text: string;
  }[];
  selected: T;
};

export const SelectType = <T extends number | string | boolean>({
  onSelectChange,
  options,
  selected,
}: SelectTypeProps<T>): React.ReactElement => {
  const styles = useStyles(makeStyles);

  // add useOptimistic when avaialble, otherwise the settings will only change after the API call is made and refetched

  return (
    <div className={styles.container}>
      <Select
        variant="standard"
        value={selected}
        onChange={e => onSelectChange(e.target.value as T)}
      >
        {options.map(option => (
          <MenuItem
            key={option.value.toString()}
            value={option.value.toString()}
          >
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(4)};
    .MuiInputBase-input {
      padding: ${theme.spacing(1.5, 2)};
      margin: 0;
      width: ${theme.spacing(96)};
    }
  `,
});
