import {
  DecoderFunction,
  boolean,
  number,
  string,
} from 'typescript-json-decoder';

import { recordWithContext, stringUnion } from '@/utils/decoderUtils';

export type AlertType = 'bgm' | 'cgm' | 'other';
export type PeriodType = 'fasting' | 'before_meal' | 'after_meal' | 'all';
export type DefaultAlertsParameters = Record<
  AlertType,
  Record<AlertCategory, AlertParameters>
>;

export type AlertCategory =
  | 'severe_hypoglycemia_declared'
  | 'hyperglycemia'
  | 'hypoglycemia'
  | 'hyperglycemia'
  | 'time_above_range'
  | 'time_below_range'
  | 'observance_transmission'
  | 'observance_measures';

export type AlertSummary = {
  id: string;
  patient_id: string;
  category: AlertCategory;
};

export const alertSummaryDecoder: DecoderFunction<AlertSummary> =
  recordWithContext('AlertSummary', {
    id: string,
    patient_id: string,
    category: stringUnion(
      'severe_hypoglycemia_declared',
      'hyperglycemia',
      'hypoglycemia',
      'hyperglycemia',
      'time_above_range',
      'time_below_range',
      'observance_transmission',
      'observance_measures',
    ),
  });

export type AlertConfig = {
  id: string;
  category: AlertCategory;
  can_delete: boolean;
  type: AlertType;
  parameters?: unknown;
};

export const alertConfigDecoder: DecoderFunction<AlertConfig> =
  recordWithContext('AlertConfig', {
    id: string,
    category: stringUnion(
      'severe_hypoglycemia_declared',
      'hyperglycemia',
      'hypoglycemia',
      'hyperglycemia',
      'time_above_range',
      'time_below_range',
      'observance_transmission',
      'observance_measures',
    ),
    type: stringUnion('bgm', 'cgm', 'other'),
    can_delete: boolean,
    parameters: value => (value === null ? undefined : value),
  });

export type AlertParameters =
  | HypoglycemiaAlertParametersCGM
  | HyperglycemiaAlertParametersCGM
  | HypoglycemiaAlertParametersBGM
  | HyperglycemiaAlertParametersBGM
  | TimeAboveRangeCGM
  | TimeInRangeCGM
  | TimeBelowRangeCGM
  | TransmissionAlertParameters
  | MeasurementAlertParameters;

export type CreateAlertConfig = {
  type: AlertType;
  category: AlertCategory;
};

const hypoglycemiaAlertParametersBGMDecoder: DecoderFunction<HypoglycemiaAlertParametersBGM> =
  recordWithContext('HypoglycemiaAlertParametersBGM', {
    max_number_of_events: number,
    severe_only: boolean,
    periods: stringUnion('fasting', 'before_meal', 'after_meal', 'all'),
    number_of_days: number,
  });

// Decoder for HypoglycemiaAlertParametersCGM
const hypoglycemiaAlertParametersCGMDecoder: DecoderFunction<HypoglycemiaAlertParametersCGM> =
  recordWithContext('HypoglycemiaAlertParametersCGM', {
    max_number_of_events: number,
    severe_only: boolean,
    number_of_days: number,
  });

// Decoder for HyperglycemiaAlertParametersBGM
const hyperglycemiaAlertParametersBGMDecoder: DecoderFunction<HyperglycemiaAlertParametersBGM> =
  recordWithContext('HyperglycemiaAlertParametersBGM', {
    max_number_of_events: number,
    severe_only: boolean,
    periods: stringUnion('fasting', 'before_meal', 'after_meal', 'all'),
    number_of_days: number,
  });

// Decoder for HyperglycemiaAlertParametersCGM
const hyperglycemiaAlertParametersCGMDecoder: DecoderFunction<HyperglycemiaAlertParametersCGM> =
  recordWithContext('HyperglycemiaAlertParametersCGM', {
    max_number_of_events: number,
    severe_only: boolean,
    number_of_days: number,
  });

// Decoder for TimeAboveRangeCGM
const timeAboveRangeCGMDecoder: DecoderFunction<TimeAboveRangeCGM> =
  recordWithContext('TimeAboveRangeCGM', {
    duration_proportion: number,
    severe_only: boolean,
    number_of_days: number,
  });

// Decoder for TimeBelowRangeCGM
const timeBelowRangeCGMDecoder: DecoderFunction<TimeBelowRangeCGM> =
  recordWithContext('TimeBelowRangeCGM', {
    duration_proportion: number,
    severe_only: boolean,
    number_of_days: number,
  });

// Decoder for TimeInRangeCGM
const timeInRangeCGMDecoder: DecoderFunction<TimeInRangeCGM> =
  recordWithContext('TimeInRangeCGM', {
    duration_proportion: number,
    severe_only: boolean,
    number_of_days: number,
  });

// Decoder for TransmissionAlertParameters
const transmissionAlertParametersDecoder: DecoderFunction<TransmissionAlertParameters> =
  recordWithContext('TransmissionAlertParameters', {
    number_of_days: number,
  });

// Decoder for MeasurementAlertParameters
const measurementAlertParametersDecoder: DecoderFunction<MeasurementAlertParameters> =
  recordWithContext('MeasurementAlertParameters', {
    number_of_days: number,
    min_number_of_measures_per_day: number,
  });

export type EditAlertConfig = Partial<CreateAlertConfig> & {
  id: string;
  parameters?: AlertParameters;
};
export type HypoglycemiaAlertParametersBGM = {
  max_number_of_events: number;
  severe_only: boolean;
  periods: PeriodType;
  number_of_days: number;
};

export type HypoglycemiaAlertParametersCGM = {
  max_number_of_events: number;
  severe_only: boolean;
  number_of_days: number;
};

export type HyperglycemiaAlertParametersBGM = {
  max_number_of_events: number;
  severe_only: boolean;
  periods: PeriodType;
  number_of_days: number;
};

export type HyperglycemiaAlertParametersCGM = {
  max_number_of_events: number;
  severe_only: boolean;
  number_of_days: number;
};

export type TimeAboveRangeCGM = {
  duration_proportion: number;
  severe_only: boolean;
  number_of_days: number;
};

export type TimeBelowRangeCGM = {
  duration_proportion: number;
  severe_only: boolean;
  number_of_days: number;
};

export type TimeInRangeCGM = {
  duration_proportion: number;
  severe_only: boolean;
  number_of_days: number;
};

export type TransmissionAlertParameters = {
  number_of_days: number;
};

export type MeasurementAlertParameters = {
  number_of_days: number;
  min_number_of_measures_per_day: number;
};

const bgmParametersDecoder: DecoderFunction<Record<string, AlertParameters>> =
  recordWithContext('BGMParameters', {
    hypoglycemia: hypoglycemiaAlertParametersBGMDecoder,
    hyperglycemia: hyperglycemiaAlertParametersBGMDecoder,
  });

const cgmParametersDecoder: DecoderFunction<Record<string, AlertParameters>> =
  recordWithContext('CGMParameters', {
    hypoglycemia: hypoglycemiaAlertParametersCGMDecoder,
    hyperglycemia: hyperglycemiaAlertParametersCGMDecoder,
    time_above_range: timeAboveRangeCGMDecoder,
    time_below_range: timeBelowRangeCGMDecoder,
    time_in_range: timeInRangeCGMDecoder,
  });

const otherParametersDecoder: DecoderFunction<Record<string, AlertParameters>> =
  recordWithContext('OtherParameters', {
    observance_transmission: transmissionAlertParametersDecoder,
    observance_measures: measurementAlertParametersDecoder,
    severe_hypoglycemia_declared: transmissionAlertParametersDecoder,
  });

export const defaultAlertsParametersDecoder: DecoderFunction<DefaultAlertsParameters> =
  recordWithContext('DefaultAlertsParameters', {
    bgm: bgmParametersDecoder,
    cgm: cgmParametersDecoder,
    other: otherParametersDecoder,
  });
