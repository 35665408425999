import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { FontSizes } from '@/assets/fonts';
import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';

export const AlertIconNotification = () => {
  const styles = useStyles(makeStyles);

  return <Icons.alertTriangle className={styles.icon} />;
};

const makeStyles = (theme: Theme) => ({
  icon: css`
    width: ${FontSizes.bodyLarge};
    height: ${FontSizes.bodyLarge};
    fill: ${theme.palette.error.main};
  `,
});
