import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles.ts';
import { TeleMonitoringCriteria } from '@/models/TelemonitoringCriteriaModel.ts';
import { TelemonitoringFormData } from '@/pages/patients/telemonitoringForms/TelemonitoringInformation.tsx';
import { InformationPaper } from '@/uiKit/atoms/InformationPaper.tsx';

export const TelemonitoringPumpInfoBox = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const telemonitoringCriteria = useWatch<TelemonitoringFormData>({
    name: 'carePlan.telemonitoringCriteria',
  }) as TeleMonitoringCriteria | undefined;

  if (
    telemonitoringCriteria === 'pump_monitoring' ||
    telemonitoringCriteria === 'pump_initiation'
  ) {
    return (
      <InformationPaper className={styles.container}>
        <Typography sx={{ fontWeight: 'bold' }}>
          {t('diabetesForms.supportedPumpsTitle')}
          {' : '}
          <Typography component="span">
            {t('diabetesForms.supportedPumpsList')}
          </Typography>
        </Typography>

        <Typography>{t('diabetesForms.supportedPumpsInfo')}</Typography>
      </InformationPaper>
    );
  }

  return null;
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing(0, 0, 12, 0)};
  `,
});
