import React from 'react';

import { css } from '@emotion/css';
import {
  Alert,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { AlertConfig, AlertType } from '@/models/AlertModel';
import { AlertSettingsRow } from '@/pages/patient-settings/alert-settings/AlertSettingsRow';

export type AlertSettingsTableProps = {
  patientId: string;
  alertConfigList: AlertConfig[];
  alertType: AlertType;
};

export const AlertSettingsTable: React.FC<AlertSettingsTableProps> = ({
  patientId,
  alertConfigList,
  alertType,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  const alerts = alertConfigList.filter(
    alertConfig => alertConfig.type === alertType,
  );

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <TableHead className={styles.tableHeader}>
          <TableRow className={styles.row}>
            <TableCell className={styles.typeColumn}>
              <Typography variant="h6">{t('alertConfig.type')}</Typography>
            </TableCell>
            <TableCell className={styles.conditionsColumn}>
              <Typography variant="h6">
                {t('alertConfig.conditions')}
              </Typography>
            </TableCell>
            <TableCell className={styles.actionColumn}></TableCell>
          </TableRow>
        </TableHead>
        <tbody>
          {alerts.length > 0 ? (
            alerts.map(alertConfig => (
              <AlertSettingsRow
                key={alertConfig.id}
                patientId={patientId}
                alertConfig={alertConfig}
                className={styles.row}
                classes={{
                  typeCol: styles.typeColumn,
                  conditionsCol: styles.conditionsColumn,
                  actionCol: styles.actionColumn,
                }}
              />
            ))
          ) : (
            <Alert severity="info" className={styles.margin}>
              <Typography>{t('alertConfig.noAlertConfig')}</Typography>
            </Alert>
          )}
        </tbody>
      </table>
    </div>
  );
};

const makeStyles = () => ({
  container: css``,
  table: css`
    width: 100%;
  `,
  tableHeader: css`
    width: 100%;
  `,
  row: css`
    display: flex;
    width: 100%;
  `,
  typeColumn: css`
    max-width: 200px;
    min-width: 100px;
    flex: 1 1 200px;
    display: flex;
    align-items: center;
  `,
  conditionsColumn: css`
    min-width: 200px;
    flex: 3 1;
    display: flex;
    align-items: center;
  `,
  actionColumn: css`
    min-width: 50px;
    flex: 0 0 50px;
    display: flex;
    align-items: center;
  `,
  margin: css`
    margin: 16px;
  `,
});
