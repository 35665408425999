import { DecoderFunction, string } from 'typescript-json-decoder';

import {
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

export type GlycemiaDataDisplayType = 'continuous' | 'logbook';
export type GlycemiaIndicatorsDisplayType = 'bgm' | 'cgm';
export type InsulinDataDisplayType = 'discrete' | 'continuous';
export type InsulinIndicatorsDisplayType = 'discrete' | 'continuous';
export type DiabetesDeviceName = string;

export type DiabetesDataDisplayParams = {
  glycemia_data_display_mode?: GlycemiaDataDisplayType;
  insulin_data_display_mode?: InsulinDataDisplayType;
  glycemia_indicators_display_mode?: GlycemiaIndicatorsDisplayType;
  insulin_indicators_display_mode?: InsulinIndicatorsDisplayType;
  glycemia_device_name?: DiabetesDeviceName;
};

export const diabetesDisplayModeDecoder: DecoderFunction<DiabetesDataDisplayParams> =
  recordWithContext('DiabetesDataDisplayParams', {
    glycemia_data_display_mode: nullOrUndef(
      stringUnion('continuous', 'logbook'),
    ),
    insulin_data_display_mode: nullOrUndef(
      stringUnion('discrete', 'continuous'),
    ),
    glycemia_indicators_display_mode: nullOrUndef(stringUnion('bgm', 'cgm')),
    insulin_indicators_display_mode: nullOrUndef(
      stringUnion('discrete', 'continuous'),
    ),
    glycemia_device_name: nullOrUndef(string),
  });
